import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    background: {
      paper: "#282828",
    },
    mode: "dark",
    primary: {
      light: "#e45b5b",
      main: "#DD3333",
      dark: "#a41b1b",
    },
    secondary: {
      light: "#62dddd",
      main: "#1E8B8B",
      dark: "#135757",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
        outlined: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
  },
};

export default theme;
