import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

import { logoutUser, currentUser } from "./api/users";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const userCookie = Cookies.get("user");
  const initialUserState = userCookie ? JSON.parse(userCookie) : null;
  const [user, setUser] = useState(initialUserState);

  useEffect(() => {
    const checkAuthenticationStatus = async () => {
      const response = await currentUser();
      if (response?.data.loggedIn) {
        setUser(response.data.user);
        // Store user data in Cookies
        Cookies.set("user", JSON.stringify(response.data.user));
      } else {
        setUser(null);
        Cookies.remove("user");
      }
    };

    checkAuthenticationStatus();

    // Run the function every 24 hours
    const intervalId = setInterval(
      checkAuthenticationStatus,
      24 * 60 * 60 * 1000
    );

    // Clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const login = (userData) => {
    setUser(userData);
    Cookies.set("user", JSON.stringify(userData));
  };

  const logout = async () => {
    setUser(null);
    Cookies.remove("user");
    await logoutUser();
  };

  const updateUserData = (newUserData) => {
    setUser(newUserData);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
