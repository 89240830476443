// apiHandler.jsx
import axios from "axios";

// Create axios instance for API calls
const instance = axios.create({
  baseURL: import.meta.env.DEV
    ? import.meta.env.VITE_LOCAL_API_URL
    : import.meta.env.VITE_APP_API_URL,
  withCredentials: true,
});

// Response interceptor to handle unauthorized responses (401)
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    return Promise.reject(error);
  }
);

export const apiHandler = async (
  requestType,
  url,
  data = {},
  headers = {},
  progressHandler = null
) => {
  try {
    let response;
    const config = {
      headers,
      onUploadProgress: progressHandler,
    };
    switch (requestType) {
      case "GET":
        response = await instance.get(url, config);
        break;
      case "POST":
        response = await instance.post(url, data, config);
        break;
      case "PATCH":
        response = await instance.patch(url, data, config);
        break;
      case "DELETE":
        response = await instance.delete(url, config);
        break;
      default:
        throw new Error("Invalid request type");
    }
    if (response.status >= 200 && response.status < 300) {
      return {
        status: "success",
        data: response.data,
        message: response.data.message,
      };
    } else {
      return {
        status: "error",
        message: response.data.message || "An error occurred",
      };
    }
  } catch (error) {
    console.error("Error processing request:", error);
    return {
      status: "error",
      message: error.response?.data?.message || "An error occurred",
    };
  }
};
