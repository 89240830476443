// users.jsx
import { apiHandler } from "./apiHandler";

export const registerUser = (userData) =>
  apiHandler("POST", "/api/users/register", userData);

export const loginUser = (userData) =>
  apiHandler("POST", "/api/users/login", userData);

export const logoutUser = () => apiHandler("POST", "/api/users/logout");

export const currentUser = () => apiHandler("GET", "/api/users/user");

export const changePassword = (passwordData) =>
  apiHandler("POST", "/api/users/change-password", passwordData);

export const forgotPassword = (email) =>
  apiHandler("POST", "/api/users/forgot-password", email);

export const resetPassword = (data) =>
  apiHandler("POST", `/api/users/reset-password/${data.token}`, data);

export const verifyEmail = (token) =>
  apiHandler("GET", `/api/users/verify-email/${token}`);

export const resendVerificationEmail = (email) =>
  apiHandler("POST", "/api/users/resend-verification-email", email);
