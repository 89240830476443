import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Set the height to 100% of the viewport height
        width: "100%",
        top: 0,
        left: 0,
        zIndex: 9999, // Set a high zIndex to overlay other components
      }}
    >
      <CircularProgress size={80} thickness={4} />
    </Box>
  );
};

export default LoadingSpinner;
