import ReactDOM from "react-dom/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lazy, Suspense } from "react";
import * as Sentry from "@sentry/react";

import theme from "./theme";
import LoadingSpinner from "./components/LoadingSpinner";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./AuthContext";
import { AlertProvider } from "./AlertContext";

const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
const Routes = lazy(() => import("./Routes"));

Sentry.init({
  dsn: "https://2cc46e7a94ef4e328da9943a621731ad@o4505417035939840.ingest.sentry.io/4505522480087040",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://api.escapedirector.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/live-view-service-worker.js", { scope: "/" })
      .then((registration) => {
        console.log(
          "Live View Service Worker registered successfully:",
          registration
        );
      })
      .catch((error) => {
        console.log("Live View Service Worker registration failed:", error);
      });
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthProvider>
    <AlertProvider>
      <Elements stripe={loadStripe(STRIPE_PUBLIC_KEY)}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes />
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </Elements>
    </AlertProvider>
  </AuthProvider>
);
